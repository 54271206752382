<template>
  <div id="MainBody">
    <div class="container-fluid" prefix="Header">
      <div
        class="row"
        :class="{
          'align-items-center': $windowWidth > 1380,
          'align-items-start': $windowWidth < 1380,
        }"
      >
        <div class="col-4">
          <img
            class="img-fluid"
            :style="
              $windowWidth > 1380
                ? 'max-height:50px'
                : 'max-height:40px;margin-top: 10px;'
            "
            src="../assets/india/image/logo.png"
          />
        </div>
        <div class="col-8">
          <p class="text-right m-0">
            <router-link
              :to="{
                name: 'CallRequest',
                params: { username: $route.params.username },
              }"
              v-slot="{ href, navigate }"
              custom
            >
              <a
                class="btn btn-warning m-2 btn_orange_active"
                :href="href"
                @click="navigate"
                :class="{
                  'btn-lg': $windowWidth > 1380,
                  'btn-sm': $windowWidth < 800,
                }"
                >Request A Callback</a
              >
            </router-link>
          </p>
        </div>
      </div>
    </div>
    <div class="row position-relative m-0">
      <div class="container-fluid p-4">
        <img
          class="position-absolute"
          style="height: 150px"
          src="../assets/india/image/bg-material.png"
        />
        <img
          class="position-absolute"
          style="height: 80px; right: 37%; top: 30%"
          src="../assets/india/image/bg-material.png"
        />
        <img
          class="position-absolute"
          style="right: 1.5rem"
          src="../assets/india/image/bg-design-1.png"
        />
        <div class="row m-0">
          <div
            class="p-md-5 p-sm-3 p-1"
            :class="{
              'col-7': $windowWidth >= 1000,
              'col-12': $windowWidth < 1000,
            }"
          >
            <h1
              style="color: #3e3e3f; font-size: 3.5rem; margin-top: 65px"
              v-if="$windowWidth >= 1000"
            >
              <strong> CODING </strong>
              FOR SCHOOLS
            </h1>
            <h1 style="color: #3e3e3f" v-else>
              <strong> CODING </strong>FOR SCHOOLS
            </h1>
           

            <p
              :style="
                $windowWidth >= 1000 ? 'font-size:1.2rem' : 'font-size:0.9rem'
              "
            >
              Tinkerly supports K-12 schools to offer subjects like <br />
              Coding, AI, and Robotics as per NEP Guidelines
            </p>
            <p
              style="
                width: 200px;
                height: 1px;
                border: 2px solid #ffcc00;
                background: #ffcc00;
                border-radius: 102px;
                margin: 20px 0px 50px -30px;
                margin-left: -40px;
              "
            ></p>

             <img
              v-if="$windowWidth < 1000"
              style="width: 100%;margin: 20px;"
              src="@/assets/school/cover.jpg"
            />
            <router-link
              :to="{
                name: 'CallRequest',
                params: { username: $route.params.username },
              }"
              v-slot="{ href, navigate }"
              custom
            >
              <a
                class="btn btn-warning btn_orange_active"
                :href="href"
                @click="navigate"
                :class="{
                  'btn-lg': $windowWidth > 1380,
                  'btn-sm': $windowWidth < 800,
                }"
                >Request A Callback </a
              >
            </router-link>
            <p
              style="
                text-align: center;
                font-size: 0.9rem;
                width: 218px;
                margin-top: 20px;
              "
            >
              Get 2 weeks of free classes <br />
              for all students
            </p>
          </div>
          <div class="col-5 p-5" v-if="$windowWidth >= 1000">
            <img style="width: 100%" src="@/assets/school/cover.jpg" />
          </div>
        </div>
      </div>
      <img
        class="position-absolute p-0"
        style="width: 100%; bottom: 0; z-index: -1"
        src="../assets/india/image/header-bg.png"
      />
    </div>
    <div
      :style="{
        backgroundImage: 'url(' + require('@/assets/india/image/bg.jpg') + ')',
      }"
    >
      <div class="container-fluid py-5">
        <div class="row justify-content-around">
          <div class="col-lg-2 col-4">
            <h2 class="text-center mb-0" style="font-size: calc(1rem + 1.2vw)">
              <img
                src="@/assets/school/student.png"
                style="height: 100px"
              /><br /><br />
              150,000+
            </h2>
            <p class="text-center mt-0">Students</p>
          </div>
          <div class="col-lg-2 col-4">
            <h2 class="text-center mb-0" style="font-size: calc(1rem + 1.2vw)">
              <img
                src="@/assets/school/teacher.png"
                style="height: 100px"
              /><br /><br />
              7000+
            </h2>
            <p class="text-center mt-0">Teachers</p>
          </div>
          <div class="col-lg-2 col-4">
            <h2 class="text-center mb-0" style="font-size: calc(1rem + 1.2vw)">
              <img
                src="@/assets/school/learning-hr.png"
                style="height: 100px"
              /><br /><br />
              300,000+
            </h2>
            <p class="text-center mt-0">learning hours</p>
          </div>
        </div>
      </div>
    </div>
    <div class="row position-relative m-0">
      <div
        class="container p-1 p-sm-2 p-md-3 p-lg-4"
        style="padding-bottom: 0px !important"
      >
        <h2
          class="text-center p-1 p-sm-2 p-md-3 p-lg-4 m-0"
          style="padding-bottom: 0px !important"
        >
          Benefits For <strong>Students</strong>
        </h2>
        <p style="position: relative; margin: 0px">
          <sapn
            style="
              width: 200px;
              display: block;
              border: 2px solid rgb(255, 204, 0);
              background: rgb(255, 204, 0);
              border-radius: 102px;
              position: absolute;
              left: calc(50% - 100px);
            "
          ></sapn>
        </p>

        <div class="row justify-content-center mt-5">
          <div class="col-12 col-sm-9 col-md-4 mb-3 mb-sm-2 mb-1 p-3">
            <p class="text-center mb-0">
              <img
                class="img-fluid rounded"
                style="border-radius: 4rem !important"
                src="@/assets/sa/img/why_early.jpg"
              />
            </p>
          </div>
          <div class="col-12 col-sm-9 col-md-5 mb-3 mb-sm-2 mb-1">
            <p class="text-center mb-0">
              <img class="img-fluid" src="@/assets/sa/img/icon_why_early.jpg" />
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="row position-relative m-0 pb-5">
      <div
        class="container p-1 p-sm-2 p-md-3 p-lg-4"
        style="padding-bottom: 0px !important"
      >
        <h2
          class="text-center p-1 p-sm-2 p-md-3 p-lg-4 m-0"
          style="padding-bottom: 0px !important"
        >
          Benefits For <strong>Schools</strong>
        </h2>
        <p style="position: relative; margin: 0px">
          <sapn
            style="
              width: 200px;
              display: block;
              border: 2px solid rgb(255, 204, 0);
              background: rgb(255, 204, 0);
              border-radius: 102px;
              position: absolute;
              left: calc(50% - 100px);
            "
          ></sapn>
        </p>

        <div class="row justify-content-center mt-5">
          <div class="col-lg-2 col-md-3 col-6">
            <p class="text-center mb-0">
              <img
                src="@/assets/school/benifit-school-1.png"
                style="height: 130px"
              /><br />
              Design, Create and Build the Innovation-<br /><strong
                >Be the Captain of Change!</strong
              >
            </p>
          </div>
          <div class="col-lg-2 col-md-3 col-6">
            <p class="text-center mb-0">
              <img
                src="@/assets/school/benifit-school-2.png"
                style="height: 130px"
              /><br />
              Coding is the language of the future hence, <br /><strong
                >Students will become Future Ready
              </strong>
            </p>
          </div>
          <div class="col-lg-2 col-md-3 col-6">
            <p class="text-center mb-0">
              <img
                src="@/assets/school/benifit-school-3.png"
                style="height: 130px"
              /><br />
              Train Your Teachers with Coding - <br /><strong
                >Entire School Culture Upgrades!
              </strong>
            </p>
          </div>
          <div class="col-lg-2 col-md-3 col-6">
            <p class="text-center mb-0">
              <img
                src="@/assets/school/benifit-school-4.png"
                style="height: 130px"
              /><br />
              Become a<br /><strong>NEP Compliant School</strong>
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="row position-relative m-0" style="background: #33cc99">
      <div class="container-fluid p-5">
        <img
          class="position-absolute"
          style="height: 200px; top: 100px; z-index: 1; left: 0px"
          src="../assets/school/overlaping-5.png"
        />
        <img
          class="position-absolute"
          style="right: 1.5rem; z-index: 1; height: 300px"
          src="../assets/school/overlaping-5.png"
        />
        <h2 class="text-center p-0 mt-5 mb-0" style="color: white">
          Our <strong>Offerings</strong>
        </h2>
        <p style="position: relative; margin: 0px">
          <sapn
            style="
              width: 200px;
              display: block;
              border: 2px solid rgb(255, 204, 0);
              background: rgb(255, 204, 0);
              border-radius: 102px;
              position: absolute;
              left: calc(50% - 100px);
            "
          ></sapn>
        </p>

        <div class="container">
          <div class="row">
            <div class="col-md-6 col-12">
              <img
                src="@/assets/school/offereing-1.png"
                style="width: 100%; max-width: 150px"
              />
              <p class="mt-3" style="font-size: 1.2rem">
                <strong>For School</strong>
              </p>
              <ul style="font-size: 1.5rem">
                <li>Year-long Curriculum and Lesson Plan</li>
                <li>Weekly Instructor-led Live Classes - 40 in Each Grade</li>
                <li>
                  Grade-wise Syllabus from App Development to Artificial
                  Intelligence
                </li>
                <li>Complementary Training of School Teachers</li>
                <li>
                  Hands-on Projects with Educational Kits & Hardware (Optional)
                </li>
                <li>
                  Learn Beyond Coding - Make Projects for Science and
                  Engineering Fairs
                </li>
                <li>Marking Grades to Students in the Course (Optional)</li>
                <li>Refresher Classes for Students Joining Mid-program</li>
              </ul>
            </div>
            <div class="col-md-6 col-12">
              <img
                src="@/assets/school/offereing.png"
                style="width: 100%; max-width: 150px"
              />
              <p class="mt-3" style="font-size: 1.2rem">
                <strong>For Students </strong>
              </p>
              <ul style="font-size: 1.5rem">
                <li>Coding Course Certification by the US-based STEM.org</li>
                <li>Intuitive and Animated Video Lessons for Revision</li>
                <li>Gamified Practice on Simulation Plateforms</li>
                <li>24x7 Doubt Support by Experts</li>
                <li>
                  Complimentary Membership of Lets Tinker Online Innovators'
                  Community
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container p-5">
      <p class="text-center" style="font-size: 1.5em">
        <strong>Support Required From Schools</strong>
      </p>
      <div class="row justify-content-center">
        <div class="col-md-3 col-4">
          <p class="text-center">
            <img
              src="@/assets/school/school-support-1.png"
              style="width: 100%; max-width: 150px"
            /><br />
            Computer Lab With Smart <br />
            Class/Projector Setup
          </p>
        </div>
        <div class="col-md-3 col-4">
          <p class="text-center">
            <img
              src="@/assets/school/school-support-2.png"
              style="width: 100%; max-width: 150px"
            /><br />
            In-class Teaching <br />
            Assistance
          </p>
        </div>
        <div class="col-md-3 col-4">
          <p class="text-center">
            <img
              src="@/assets/school/school-support-3.png"
              style="width: 100%; max-width: 150px"
            /><br />
            High Speed <br />
            Internet
          </p>
        </div>
      </div>
    </div>
    <div class="row position-relative m-0 pb-5">
      <div
        class="container p-1 p-sm-2 p-md-3 p-lg-4"
        style="padding-bottom: 0px !important"
      >
        <div class="row justify-content-around mt-5" style="position: relative">
          <div class="col-12">
            <h2
              class="text-center p-1 p-sm-2 p-md-3 p-lg-4 m-0"
              style="padding-bottom: 0px !important"
            >
              <strong>No Interruption</strong> Due to <br />Lockdown/School
              Closure
            </h2>
            <p style="position: relative; margin: 0px">
              <sapn
                style="
                  width: 200px;
                  display: block;
                  border: 2px solid rgb(255, 204, 0);
                  background: rgb(255, 204, 0);
                  border-radius: 102px;
                  position: absolute;
                  left: calc(50% - 100px);
                "
              ></sapn>
            </p>
          </div>
          <div class="col-md-5 col-12 p-lg-5 p-md-3 p-1">
            <img
              src="@/assets/school/overlaping-6.png"
              style="
                position: absolute;
                top: 20px;
                z-index: -1;
                left: 9%;
                width: 200px;
              "
            />
            <img src="@/assets/school/on-school.jpg" class="img-fluid" />
          </div>
          <div class="col-md-5 col-12 p-lg-5 p-md-3 p-1">
            <img
              src="@/assets/school/overlaping-6.png"
              style="
                z-index: -1;
                position: absolute;
                width: 200px;
                right: 5%;
                bottom: 0px;
              "
            />
            <img src="@/assets/school/on-home.jpg" class="img-fluid" />
          </div>
        </div>
      </div>
    </div>
    <div class="row m-0 pb-5" style="background: #f3f4f7">
      <div
        class="container p-1 p-sm-2 p-md-3 p-lg-4"
        style="padding-bottom: 0px !important"
      >
        <h2
          class="text-center p-1 p-sm-2 p-md-3 p-lg-4 m-0"
          style="padding-bottom: 0px !important"
        >
          Sequential Development of <strong>Coding Skills </strong>
        </h2>
        <p style="position: relative; margin: 0px">
          <sapn
            style="
              width: 200px;
              display: block;
              border: 2px solid rgb(255, 204, 0);
              background: rgb(255, 204, 0);
              border-radius: 102px;
              position: absolute;
              left: calc(50% - 100px);
            "
          ></sapn>
        </p>

        <div class="row justify-content-around mt-5" >
          <div class="col-12" style="position: relative;min-height: 320px;" v-if='$windowWidth < 768'>
            <div style="width:400px;position:absolute;left: calc(50% - 200px);height:300px;">
                <img
              src="@/assets/school/skill_1.png"
              style="
                position: absolute;
                width: 150px;
                max-width: 150px;
                left: calc(50% - 75px);
              "
            />
            <img
              src="@/assets/school/skill_2a.png"
              style="
                position: absolute;
                width: 220px;
                max-width: 220px;
                left: calc(50% - 110px);
                top: 75px;
              "
            />
            <!-- nitin -->
            <a class="skill_a upper"  :class="[(activeVideo==5) ? 'active':'']" @click='setSlide(1)'>
   <img src="@/assets/school/1uw.png" /> 
   <img src="@/assets/school/1ug.png" />
</a>
<a class="skill_a upper" @click='setSlide(2)' :class="[(activeVideo==4) ? 'active':'']"> 
   <img src="@/assets/school/2uw.png" /> 
   <img src="@/assets/school/2ug.png" />
</a>
<a class="skill_a upper" @click='setSlide(3)' :class="[(activeVideo==3) ? 'active':'']">
   <img src="@/assets/school/3uw.png" /> 
   <img src="@/assets/school/3ug.png" />
</a>
<a class="skill_a upper" @click='setSlide(4)' :class="[(activeVideo==2) ? 'active':'']">
   <img src="@/assets/school/4uw.png" /> 
   <img src="@/assets/school/4ug.png" />
</a>
<a class="skill_a upper" @click='setSlide(5)' :class="[(activeVideo==1) ? 'active':'']">
   <img src="@/assets/school/5uw.png" /> 
   <img src="@/assets/school/5ug.png" />
</a>
            </div>
          </div>
          <div class="col-md-5 col-12" style="position: relative;min-height: 420px;" v-else>
            <div style="height:400px;position:absolute;top: calc(50% - 200px);width:300px;">
                <img
              src="@/assets/school/skill_1.png"
              style="
                position: absolute;
                height: 150px;
                max-height: 150px;
                top: calc(50% - 75px);
              "
            />
            <img
              src="@/assets/school/skill_2.png"
              style="
                position: absolute;
                height: 220px;
                max-height: 220px;
                top: calc(50% - 110px);
                left: 75px;
              "
            />
            <!-- nitin -->
            <a class="skill_a left"  :class="[(activeVideo==1) ? 'active':'']" @click='setSlide(5)'>
   <img src="@/assets/school/skillss-1a.png" /> 
   <img src="@/assets/school/skillss-1b.png" />
</a>
<a class="skill_a  left" @click='setSlide(4)' :class="[(activeVideo==2) ? 'active':'']"> 
   <img src="@/assets/school/skillss-2a.png" /> 
   <img src="@/assets/school/skillss-2b.png" />
</a>
<a class="skill_a  left" @click='setSlide(3)' :class="[(activeVideo==3) ? 'active':'']">
   <img src="@/assets/school/skillss-3a.png" /> 
   <img src="@/assets/school/skillss-3b.png" />
</a>
<a class="skill_a  left" @click='setSlide(2)' :class="[(activeVideo==4) ? 'active':'']">
   <img src="@/assets/school/skillss-4a.png" /> 
   <img src="@/assets/school/skillss-4b.png" />
</a>
<a class="skill_a  left" @click='setSlide(1)' :class="[(activeVideo==5) ? 'active':'']">
   <img src="@/assets/school/skillss-5a.png" /> 
   <img src="@/assets/school/skillss-5b.png" />
</a>
            </div>
          </div>
          <div class="col-md-7 col-12">
               <swiper :slidesPerView="'1'" @swiper="VideoInit" @slideChange="ChangeVideoSlide" :spaceBetween="30"  :autoplay='{
  "delay": 5000,
  "disableOnInteraction": false,
  "pauseOnMouseEnter":true
}' 
>
                   
                   
                  
                   <swiper-slide>
                       <video ref="videoplayer5" src="/video/5.m4v" style="pointer-events: none;width:100%" loop='true'  />
                   </swiper-slide>
                   <swiper-slide>
                       <video ref="videoplayer4" src="/video/4.m4v" style="pointer-events: none;width:100%" loop='true'  />
                   </swiper-slide>
                    <swiper-slide>
                       <video ref="videoplayer3" src="/video/3.m4v" style="pointer-events: none;width:100%" loop='true'  />
                   </swiper-slide>
                     <swiper-slide>
                       <video ref="videoplayer2" src="/video/2.m4v" style="pointer-events: none;width:100%" loop='true'  />
                   </swiper-slide>
                     <swiper-slide>
                       <video ref="videoplayer1" src="/video/1.m4v" style="pointer-events: none;width:100%" loop='true'  />
                   </swiper-slide>
                    
               </swiper>

          </div>
        </div>
      </div>
    </div>
      <div class="row m-0 pb-5" >
      <div
        class="container p-1 p-sm-2 p-md-3 p-lg-4"
        style="padding-bottom: 0px !important"
      >
        <h2
          class="text-center p-1 p-sm-2 p-md-3 p-lg-4 m-0"
          style="padding-bottom: 0px !important"
        >
          Grade-wise <strong>Course Plan</strong>
        </h2>
        <p style="position: relative; margin: 0px">
          <sapn
            style="
              width: 200px;
              display: block;
              border: 2px solid rgb(255, 204, 0);
              background: rgb(255, 204, 0);
              border-radius: 102px;
              position: absolute;
              left: calc(50% - 100px);
            "
          ></sapn>
        </p>

        <div class="row justify-content-center mt-5" >
          <div class="col-md-8 col-12">
            <table style="border-spacing: 10px;border-collapse: separate;width:100%;text-align: center;">
              <tr>
                <th>Grade</th>
                <th>Course Name</th>
                <th>Platform</th>
              </tr>
              <tr><td style="background: #E2F0D9;padding: 15px;border-radius: 15px;">Grade 1</td>
                <td style="background: #FFE68A;padding: 15px;border-radius: 15px;">Application of GUI in animation and story development</td>
                <td style="background: #33CC99;padding: 15px;border-radius: 15px;">
                  <img style="height:20px;margin-right:8px;" src="@/assets/school/scratch.png"/>
                <img style="height:20px;margin-right:8px;" src="@/assets/school/lt.png"/>
                </td>
                </tr>
               <tr><td style="background: #E2F0D9;padding: 15px;border-radius: 15px;">Grade 2</td>
                <td style="background: #FFE68A;padding: 15px;border-radius: 15px;">Advance animation graphics and games development</td>
                <td style="background: #33CC99;padding: 15px;border-radius: 15px;">
                   <img style="height:20px;margin-right:8px;" src="@/assets/school/scratch.png"/>
                <img style="height:20px;margin-right:8px;" src="@/assets/school/lt.png"/>
                <img style="height:20px;margin-right:8px;" src="@/assets/school/mit.png"/>
                </td>
                </tr>
                <tr><td style="background: #E2F0D9;padding: 15px;border-radius: 15px;">Grade 3</td>
                <td style="background: #FFE68A;padding: 15px;border-radius: 15px;">Advance games and app development</td>
                <td style="background: #33CC99;padding: 15px;border-radius: 15px;">
                  <img style="height:20px;margin-right:8px;" src="@/assets/school/lt.png"/>
                <img style="height:20px;margin-right:8px;" src="@/assets/school/mit.png"/>
                <img style="height:20px;margin-right:8px;" src="@/assets/school/cad.png"/>
                </td>
                </tr>
                <tr><td style="background: #C5E0B4;padding: 15px;border-radius: 15px;">Grade 4</td>
                <td style="background: #FCCC38;padding: 15px;border-radius: 15px;">Advance app development & coding of controllers </td>
                <td style="background: #33CC99;padding: 15px;border-radius: 15px;">
                  <img style="height:20px;margin-right:8px;" src="@/assets/school/lt.png"/>
                <img style="height:20px;margin-right:8px;" src="@/assets/school/cad.png"/>
                </td>
                </tr>
                <tr><td style="background: #C5E0B4;padding: 15px;border-radius: 15px;">Grade 5</td>
                <td style="background: #FCCC38;padding: 15px;border-radius: 15px;">Advance coding of controllers and iot</td>
                <td style="background: #33CC99;padding: 15px;border-radius: 15px;">
                  <img style="height:20px;margin-right:8px;" src="@/assets/school/lt.png"/>
                  <img style="height:20px;margin-right:8px;" src="@/assets/school/pnet.png"/>
                <img style="height:20px;margin-right:8px;" src="@/assets/school/cad.png"/>
                </td>
                </tr>
                <tr><td style="background: #C5E0B4;padding: 15px;border-radius: 15px;">Grade 6</td>
                <td style="background: #FCCC38;padding: 15px;border-radius: 15px;">Iot with gui coding and circuit simulation </td>
                <td style="background: #33CC99;padding: 15px;border-radius: 15px;">
                 <img style="height:20px;margin-right:8px;" src="@/assets/school/lt.png"/>
                  <img style="height:20px;margin-right:8px;" src="@/assets/school/pnet.png"/>
                <img style="height:20px;margin-right:8px;" src="@/assets/school/cad.png"/>
                </td>
                </tr>
                <tr><td style="background: #A9D18E;padding: 15px;border-radius: 15px;">Grade 7</td>
                <td style="background: #FFAA00;padding: 15px;border-radius: 15px;">Basics of C-language in IoT and robotics</td>
                <td style="background: #33CC99;padding: 15px;border-radius: 15px;">
                  <img style="height:20px;margin-right:8px;" src="@/assets/school/lt.png"/>
                  <img style="height:20px;margin-right:8px;" src="@/assets/school/ardunio.png"/>
                <img style="height:20px;margin-right:8px;" src="@/assets/school/cad.png"/>
                </td>
                </tr>
                <tr><td style="background: #A9D18E;padding: 15px;border-radius: 15px;">Grade 8</td>
                <td style="background: #FFAA00;padding: 15px;border-radius: 15px;">Advance IoT, robotics and artificial intelligence </td>
                <td style="background: #33CC99;padding: 15px;border-radius: 15px;">
                <img style="height:20px;margin-right:8px;" src="@/assets/school/lt.png"/>
                  <img style="height:20px;margin-right:8px;" src="@/assets/school/ardunio.png"/>
                <img style="height:20px;margin-right:8px;" src="@/assets/school/cad.png"/>
                <img style="height:20px;margin-right:8px;" src="@/assets/school/ml.png"/>
                </td>
                </tr>
                <tr><td style="background: #92D050;padding: 15px;border-radius: 15px;">Grade 9</td>
                <td style="background: #FF9900;padding: 15px;border-radius: 15px;">AI based game development with basics of python </td>
                <td style="background: #33CC99;padding: 15px;border-radius: 15px;">
                   <img style="height:20px;margin-right:8px;" src="@/assets/school/lt.png"/>
                <img style="height:20px;margin-right:8px;" src="@/assets/school/ml.png"/>
                <img style="height:20px;margin-right:8px;" src="@/assets/school/jupyter.png"/>
                <img style="height:20px;margin-right:8px;" src="@/assets/school/python.png"/>

                </td>
                </tr>
                <tr><td style="background: #92D050;padding: 15px;border-radius: 15px;">Grade 10</td>
                <td style="background: #FF9900;padding: 15px;border-radius: 15px;">Python based machine learning game development </td>
                <td style="background: #33CC99;padding: 15px;border-radius: 15px;">
                   <img style="height:20px;margin-right:8px;" src="@/assets/school/lt.png"/>
                <img style="height:20px;margin-right:8px;" src="@/assets/school/ml.png"/>
                <img style="height:20px;margin-right:8px;" src="@/assets/school/jupyter.png"/>
                <img style="height:20px;margin-right:8px;" src="@/assets/school/python.png"/>

                </td>
                </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid py-5" style="background: #dcf7fe">
      <h2
        class="text-center p-1 p-sm-2 p-md-3 p-lg-4"
        style="padding-bottom: 0px !important"
      >
        <strong>What</strong>
        Client Schools Say?
      </h2>
      <p style="position: relative; margin: 0px;"><sapn style="width: 200px; display: block; border: 2px solid #2bb7d0; background: #2bb7d0; border-radius: 102px; position: absolute; left: calc(50% - 100px);"></sapn></p>
      <div class="container">
        <div class="row">
          <div class="col-12 pr-5">
            <p class="text-right pr-5">
              <img
                style="cursor: pointer; height: 40px"
                @click="$refs.carousel_parent.goToPrev()"
                class="m-2"
                src="../assets/india/image/aero-selected-left.png"
              />
              <img
                style="cursor: pointer; height: 40px"
                @click="$refs.carousel_parent.goToNext()"
                class="m-2"
                src="../assets/india/image/aero-selected-right.png"
              />
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <agile ref="carousel_parent" :options="myOptions_2">
              <div class="slide p-3">
                <div
                  style="
                    background: #fff;
                    min-height: 300px;
                    border-left: 10px solid #fff;
                    border-top-right-radius: 25px;
                  "
                  class="p-3"
                >
                  <table>
                    <tr>
                      <td style="width: 100px">
                        <img
                          class="img-fluid rounded-circle"
                          style="height: 80px"
                          src="@/assets/india/image/p-summan-jangir-mother.jpg"
                        />
                      </td>
                      <td>
                        <h4 class="text-left mb-0" style="color: #ff6634">
                          Mrs. Suman Jangir
                        </h4>
                        <h5 class="text-left" style="color: #a6a6a6">Parent</h5>
                      </td>
                    </tr>
                  </table>
                  <p class="text-left pl-5 pt-3">
                    I got my son enrolled in Tinkerly’s online coding classes &
                    I must say I was completely taken by surprise when I saw my
                    son so excited and indulged in learning. I can see him
                    progress every day through the wonderful projects that he
                    has created. A big thumbs up to the Tinkerly team!!
                  </p>
                </div>
              </div>
              <div class="slide p-3">
                <div
                  style="
                    background: #fff;
                    min-height: 300px;
                    border-top-right-radius: 25px;
                  "
                  class="p-3"
                >
                  <table>
                    <tr>
                      <td style="width: 100px">
                        <img
                          class="img-fluid rounded-circle"
                          style="height: 80px"
                          src="@/assets/india/image/p-shravani-mother.jpg"
                        />
                      </td>
                      <td>
                        <h4 class="text-left mb-0" style="color: #ff6634">
                          Mrs. Shravani
                        </h4>
                        <h5 class="text-left" style="color: #a6a6a6">Parent</h5>
                      </td>
                    </tr>
                  </table>
                  <p class="text-left pl-5 pt-3">
                    When my daughter told me that she is interested in learning
                    coding, AI & robotics, I thought it’s just like her one of
                    those interests that she’ll be doing just for a week. But it
                    turned out to be the opposite of what I thought. She got so
                    happy and excited and loved to tinker and build something
                    interesting with the kit every day. Thank you, Tinkerly, for
                    proving me wrong.
                  </p>
                </div>
              </div>
              <div class="slide p-3">
                <div
                  style="
                    background: #fff;
                    min-height: 300px;
                    border-top-right-radius: 25px;
                  "
                  class="p-3"
                >
                  <table>
                    <tr>
                      <td style="width: 100px">
                        <img
                          class="img-fluid rounded-circle"
                          style="height: 80px"
                          src="@/assets/india/image/p-pramod-sharma.jpg"
                        />
                      </td>
                      <td>
                        <h4 class="text-left mb-0" style="color: #ff6634">
                          Mr. Pramod Sharma
                        </h4>
                        <h5 class="text-left" style="color: #a6a6a6">Parent</h5>
                      </td>
                    </tr>
                  </table>
                  <p class="text-left pl-5 pt-3">
                    I have never seen my son so excited and interested for any
                    class except for the Tinkerly’s coding classes. Tinkerly has
                    really made the learning fun and engaging through their
                    online classes.
                  </p>
                </div>
              </div>
              <div class="slide p-3">
                <div
                  style="
                    background: #fff;
                    min-height: 300px;
                    border-top-right-radius: 25px;
                  "
                  class="p-3"
                >
                  <table>
                    <tr>
                      <td style="width: 100px">
                        <img
                          class="img-fluid rounded-circle"
                          style="height: 80px"
                          src="@/assets/india/image/p-prerana-chaudhary-mother.jpg"
                        />
                      </td>
                      <td>
                        <h4 class="text-left mb-0" style="color: #ff6634">
                          Mrs. Prerna Chaudhary
                        </h4>
                        <h5 class="text-left" style="color: #a6a6a6">Parent</h5>
                      </td>
                    </tr>
                  </table>
                  <p class="text-left pl-5 pt-3">
                    Tinkerly is unlike any other usual coding classes. My son
                    attended the classes, and it was a great experience. It
                    indeed has strengthened the basics of coding in my son. Now
                    he builds so many different and techy stuff each day. It was
                    beyond my imagination. Kudos to the teachers!!
                  </p>
                </div>
              </div>
              <div class="slide p-3">
                <div
                  style="
                    background: #fff;
                    min-height: 300px;
                    border-top-right-radius: 25px;
                  "
                  class="p-3"
                >
                  <table>
                    <tr>
                      <td style="width: 100px">
                        <img
                          class="img-fluid rounded-circle"
                          style="height: 80px"
                          src="@/assets/india/image/p-pankaj-jangir-mother.jpg"
                        />
                      </td>
                      <td>
                        <h4 class="text-left mb-0" style="color: #ff6634">
                          Mrs. Pankaj
                        </h4>
                        <h5 class="text-left" style="color: #a6a6a6">Parent</h5>
                      </td>
                    </tr>
                  </table>
                  <p class="text-left pl-5 pt-3">
                    Tinkerly's coding course has provided a great and fantastic
                    experience for my child. I have never seen my daughter
                    taking this much interest in coding. The course was amazing,
                    and the teachers were so knowledgeable and polite. My
                    daughter has gained good knowledge and insight into coding.
                  </p>
                </div>
              </div>
            </agile>
          </div>
        </div>
      </div>
    </div>
    <div
      class="container-fluid p-lg-5 p-md-3 p-1 py-3"
      style="background: #bbf0fe"
    >
      <h4 style="font-weight: 700" class="text-center">Associated With 500+ Schools </h4>
      <div class="row justify-content-center mt-5">
        <div class="col-12">
          <swiper :slidesPerView="'auto'" :spaceBetween="30"  class="mySwiper">
  <swiper-slide style="width:150px;" v-for="index in 21" :key="index">
    <img :src="require('@/assets/school/a'+index+'.jpg')" style="width:150px"/>
  </swiper-slide>
  </swiper>
        </div>
      </div>
    </div>

    <div
      class="container-fluid py-5 position-relative"
      style="
        background-position: left bottom;
        background-size: 100%;
        background-repeat: no-repeat;
        background-color: rgb(105, 212, 196);
      "
      :style="{
        backgroundImage: 'url(' + require('@/assets/india/image/faq.png') + ')',
      }"
    >
      <h2 class="text-center p-5" style="padding-bottom: 0px !important">
        <span style="border-color: #ff9999"><strong>FAQ</strong>s</span>
      </h2>
      <div class="row py-5 m-0 justify-content-end" style="min-height: 500px">
        <div class="col-12 col-md-8 col-lg-7">
          <vue-collapsible-panel-group accordion>
            <vue-collapsible-panel :expanded="false">
              <template #title>
                On which Platform will the course be conducted?
              </template>
              <template #content>
                There are two types of sessions:<br />
                Recorded Session will be delivered on Let’s Tinker App along
                with Quiz and Assignments. Live classes will be delivered on
                Google Meet/Zoom.
              </template>
            </vue-collapsible-panel>

            <vue-collapsible-panel :expanded="false">
              <template #title>
                Is there a Refund Policy? What are the terms?
              </template>
              <template #content>
                You can cancel the subscription anytime within the first 2 Weeks
                or downgrade to other packages. We have No Questions Asked
                Refund Policy. There are zero cancellation charges and if in
                case you request a refund post 2 Weeks then only the price for
                availed sessions and kit will be recovered.
              </template>
            </vue-collapsible-panel>
            <vue-collapsible-panel :expanded="false">
              <template #title> Is there an EMI option available? </template>
              <template #content>
                Yes, no cost EMI option is available via 3rd party partners.
              </template>
            </vue-collapsible-panel>
            <vue-collapsible-panel :expanded="false">
              <template #title>
                In which language courses are available?
              </template>
              <template #content> Currently in English and Hindi. </template>
            </vue-collapsible-panel>
            <vue-collapsible-panel :expanded="false">
              <template #title>
                Are there any charges for Demo Class?
              </template>
              <template #content>
                The Demo class will be scheduled free of cost for the child and
                will include a Certificate of Participation as well which can be
                downloaded freely via the app
              </template>
            </vue-collapsible-panel>
            <vue-collapsible-panel :expanded="false">
              <template #title> How is Quality of Teachers Ensured? </template>
              <template #content>
                Our courses are curated by a team of IITians. They are backed by
                extensive experience of training teachers and students in over
                500+ schools. Live classes will be delivered by graduates of Top
                tier institutions who have an experience of mentoring and
                tutoring. All our faculties go through rigorous training to
                ensure best learning outcomes for all children.
              </template>
            </vue-collapsible-panel>
          </vue-collapsible-panel-group>
        </div>
      </div>
      <div class="row justify-content-end">
        <div class="col-9 col-md-7">
         
          <p class="text-center">
            <router-link
              :to="{
                name: 'CallRequest',
                params: { username: $route.params.username },
              }"
              v-slot="{ href, navigate }"
              custom
            >
              <a
                class="btn btn-warning m-2 btn_orange_active"
                :href="href"
                @click="navigate"
                :class="{
                  'btn-lg': $windowWidth > 1380,
                  'btn-sm': $windowWidth < 800,
                }"
                >Request A Callback </a
              >
            </router-link>
          </p>
        </div>
      </div>
    </div>
    <footer class="pt-4 pl-4 pb-4" style="background: #efefef">
      <div class="row m-0">
          <div class="col-md-4 p-5">
              <!-- //logo image -->
              <a href="https://code.tinker.ly/">
                  <img src="../assets/india/image/logo.png"
                       style="max-width: 250px"
                       class="img-fluid" /></a>

                  <p style="font-size: 1.2em" class="mt-4">
                      An initiative by IIT-D & XLRI Alumni to promote Coding Skills & STEM
                      education among young minds. It consists of superior pedagogy,
                      blended learning, and a play-based curriculum to enable interactive
                      learning for the students of grades 1-12.
                  </p>
          </div>
        <div class="col-md-8">
          <div class="footer-menu">
            <span class="menu-top-border"></span>
            <div class="menu-footer-menu-container">
                <ul class="menu-list">
                    <li class="menu-item "><a title="LetsTinker'" target="_blank" href="https://alpha.letstinker.tinker.ly/" class="nav-link" itemprop="url"><span itemprop="name">Let'sTinker</span></a></li>
                    <li class="menu-item">
                        <a title="About Us"
                           target="_blank"
                           href="https://tinker.ly/about-us/"
                           class="nav-link"
                           itemprop="url"><span itemprop="name">About Us</span></a>
                    </li>
                    <li class="menu-item">
                        <a title="Contact us"
                           target="_blank"
                           href="https://tinker.ly/contact-us/"
                           class="nav-link"
                           itemprop="url"><span itemprop="name">Contact us</span></a>
                    </li>
                    <li class="menu-item">
                        <a title="Lets Tinker"
                           target="_blank"
                           href="https://letstinker.tinker.ly/"
                           class="nav-link"
                           itemprop="url"><span itemprop="name">Lets Tinker</span></a>
                    </li>

                    <li class="menu-item">
                        <a title="Blog"
                           target="_blank"
                           href="https://tinker.ly/blog/"
                           class="nav-link"
                           itemprop="url"><span itemprop="name">Blog</span></a>
                    </li>

                </ul>
            </div>
            <span class="menu-bottom-border"></span>
          </div>
          <div class="row justify-content-end">
            <div class="col-md-10">
              <h4 class="text-mohave font-weight-bold">SOCIAL MEDIA</h4>
              <p style="color: black; font-size: 1.2em">
                Follow us to stay connected and receive instant updates.
              </p>
              <ul class="menu-list p-0 m-0 align-self-end">
                <li
                  class="menu-item"
                  style="font-size: 2em; font-weight: 900; margin-right: 25px"
                >
                  <a
                    href="https://www.instagram.com/tinkerlygroup/"
                    target="_blank"
                    ><img
                      style="height: 35px"
                      src="../assets/svg/instagram-brands.svg"
                  /></a>
                </li>

                <li
                  class="menu-item"
                  style="font-size: 2em; font-weight: 900; margin-right: 25px"
                >
                  <a
                    href="https://www.facebook.com/tinkerlygroup/"
                    target="_blank"
                    ><img
                      style="height: 35px"
                      src="../assets/svg/facebook-square-brands.svg"
                  /></a>
                </li>

                <li
                  class="menu-item"
                  style="font-size: 2em; font-weight: 900; margin-right: 25px"
                >
                  <a
                    href="https://www.youtube.com/channel/UCJccc_N6nxOtOd_2r2OCRRg"
                    target="_blank"
                    ><img
                      style="height: 35px"
                      src="../assets/svg/youtube-brands.svg"
                  /></a>
                </li>

                <li
                  class="menu-item"
                  style="font-size: 2em; font-weight: 900; margin-right: 25px"
                >
                  <a
                    href="https://www.linkedin.com/company/tinkerlygroup/"
                    target="_blank"
                    ><img
                      style="height: 35px"
                      src="../assets/svg/linkedin-brands.svg"
                  /></a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div class="container mt-5">
        <div class="row">
          <div class="col-lg-6 col-md-12 col-md-12 base-footer-left">
            <span class="base-footer-left-top-border"></span>
            <p style="font-size: 1.2em">
              Copyright © Elation Edtech Pvt Ltd 2021. All Rights Reserved
            </p>
          </div>
          <div class="col-lg-6 col-md-12 col-md-12 base-footer-right-links">
            <ul class="menu-list">
              <li class="menu-item">
                <a
                  style="
                    border-right: 1px solid #000;
                    margin-right: 25px;
                    padding-right: 25px;
                  "
                  href="https://tinker.ly/privacy-policy-for-tinkerly/"
                  target="_blank"
                >
                  Privacy Policy</a
                >
              </li>
              <li class="menu-item">
                <a
                  style="margin-right: 25px"
                  href="https://tinker.ly/terms-and-conditions/"
                  target="_blank"
                  >Terms & Conditions</a
                >
              </li>
              <li class="menu-item">
                <a
                  style="border-left: 1px solid #000; padding-left: 25px"
                  href="https://tinker.ly/guidelines-for-setting-up-of-atal-tinkering-laboratories/"
                  target="_blank"
                  >Guidelines for ATL</a
                >
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>
<style scoped>
@media screen and (min-width: 1200px) {
  h2,h1,h6{
      font-size: 2.5rem;
  }
}
@media screen and (max-width: 1200px) {
  h2,h1,h6{
      font-size: calc(1.375rem + 1.5vw);
  }
}
@media screen and (max-width: 1000px) {
  h1,h6{
      font-size: calc(1.325rem + 0.9vw);
  }
}
.try_half {
  width: 50% !important;
}
.menu-list {
  list-style-type: none;
}
.menu-item {
  list-style-type: none;
  display: inline-block;
  color: #000;
}
.menu-item a {
  font-size: 1.2em;
  color: #000;
}
.menu-bottom-border {
  border-bottom: 1px solid #000;
  display: inline-block;
  width: 95%;
  position: relative;
  right: 0;
  bottom: 16px;
}
.menu-top-border {
  border-bottom: 1px solid #000;
  display: inline-block;
  width: 97%;
  position: relative;
  left: -94px;
  bottom: 16px;
}
.base-footer-left-top-border {
  border-bottom: 1px solid #000;
  display: block;
  width: 20%;
  position: relative;
  left: 0;
  bottom: 12px;
}
.grade_display {
  top: 12%;
  left: 10%;
  z-index: 1;
  color: #fff;
  font-weight: 600;
  font-size: 120%;
  position: absolute;
}
.count_display {
  top: 10%;

  color: black;
  font-size: 160%;
  z-index: 1;
  font-weight: 700;
  position: absolute;
}
.Curriculum_display {
  position: absolute;

  left: 22%;
  width: 74%;
  font-size: 95%;
}
.Curriculum_display span {
  font-size: 120%;
  font-weight: 600;
  color: #ff6634;
}

.price_display {
  position: absolute;
  top: 88.5%;
  left: 32%;
  font-weight: 600;
}
.price_cut_display {
  position: absolute;
  top: 88.5%;
  left: 65%;
  font-weight: 600;
  color: #999999;
  text-decoration: line-through;
}
.row,
.container-fluid,
.container {
  overflow-x: hidden;
}
.position-relative .position-absolute {
  width: auto;
}
.position-absolute {
  z-index: -1;
}
.text-right,
.text-md-right {
  text-align: right;
}
* {
  font-family: "Mont" !important;
}
h1,h2,h6 {
  font-weight: 600;
}
h2 span {
  border-bottom: 4px solid;
  display: inline-block;
  line-height: 35px;
}
.btn_blue_active {
  background: #2bb7d0;
  color: #fff;
  border: 2px #2bb7d0 solid;
}
.btn_blue_active:hover {
  background: #26a2b8;
}
.btn_orange_active {
  background: #ff6633;
  color: #fff;
  border: 2px #ff6633 solid;
}
.btn_orange_active:hover {
  background: #e65c2e;
  color: #fff;
  border: 2px #ff6633 solid;
}
.btn_orange {
  background: #fff;
  color: #ff6633;
  border: 2px #ff6633 solid;
}
.btn_orange:hover {
  background: #ff6633;
  color: #fff;
}
.No_cost_emi {
  background: #fff;
  line-height: 18px;
  border-radius: 12px !important;
}
.No_cost_emi span {
  font-size: 1rem;
  line-height: 10px;
  color: #b3b3b3;
}
.mobile {
  border: 1px solid white;
  box-shadow: 0px 0px 3px 3px #e9e8e8;
  border-radius: 10px;
  background: white;
  padding-top: 0px !important;
}
.mobile p span {
  width: 10px !important;
  display: inline-block;
}
.s_red:before {
  content: "\25CF";
  color: #ff6634;
  display: inline-block;
  width: 6px;
}
.s_green:before {
  content: "\25CF";
  color: #65c3a4;
  display: inline-block;
  width: 6px;
}
.s_yello:before {
  content: "\25CF";
  color: #f9a137;
  display: inline-block;
  width: 6px;
}
</style>
<script>
import "../assets/css/bootstrap.css";
import { VueAgile } from "vue-agile";
import "vue-agile/dist/VueAgile.css";
import "../assets/Font/stylesheet.css";
import { Swiper, SwiperSlide } from "swiper/vue";

import "swiper/swiper-bundle.min.css";
import SwiperCore, { Pagination, Navigation,Autoplay } from "swiper/core";
// install Swiper modules
SwiperCore.use([Pagination, Navigation,Autoplay]);
import {
  VueCollapsiblePanel,
  VueCollapsiblePanelGroup,
} from "@dafcoe/vue-collapsible-panel";
import "@dafcoe/vue-collapsible-panel/dist/vue-collapsible-panel.css";

export default {
  name: "Home",
  components: {
    agile: VueAgile,
     Swiper,
     SwiperSlide,
    VueCollapsiblePanelGroup,
    VueCollapsiblePanel
  },
  data() {
    return {
      Mysyper: "",
      VideoSwiper:"",
      mode: 1,
      grade: 1,
      activeVideo : 5,
      pdfName: {
        m1g1: "class 1-3 for 1 to 1.pdf",
        m1g2: "class 4-6 for 1 to 1.pdf",
        m1g3: "class 7-8 for 1 to 1.pdf",
        m1g4: "class 8 for 1 to 1.pdf",
        m2g1: "class 1-3 for 1 to 5.pdf",
        m2g2: "class 4-6 for 1 to 5.pdf",
        m2g3: "class 7-8 for 1 to 5.pdf",
        m2g4: "class 8 for 1 to 5.pdf",
      },
      myOptions: {
        navButtons: false,
        dots: false,
        responsive: [
          {
            breakpoint: 1200,
            settings: {
              slidesToShow: 3,
            },
          },
          {
            breakpoint: 700,
            settings: {
              slidesToShow: 2,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
            },
          },
          {
            breakpoint: 400,
            settings: {
              slidesToShow: 1,
            },
          },
        ],
      },
      myOptions_2: {
        navButtons: false,
        dots: false,
        responsive: [
          {
            breakpoint: 1200,
            settings: {
              slidesToShow: 2,
            },
          },
          {
            breakpoint: 800,
            settings: {
              slidesToShow: 2,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
            },
          },
          {
            breakpoint: 400,
            settings: {
              slidesToShow: 1,
            },
          },
        ],
      },
      VideoOptions: {
        navButtons: false,
        dots: false,
        slidesToShow: 1,
      },
    };
  },
  methods: {
    VideoInit(swiper) {
      this.VideoSwiper = swiper;
      this.$refs.videoplayer5.play();
    },
    onSwiper(swiper) {
      this.Mysyper = swiper;
    },
    ChangeVideoSlide(swiper){
      this.activeVideo = 5 - swiper.activeIndex;
      this.getVideoForindex(swiper.activeIndex).play();
     this.getVideoForindex(swiper.previousIndex).pause();
    },
    getVideoForindex(num){
      if(num==0)
      return this.$refs.videoplayer5 ;
      else if(num==1)
      return this.$refs.videoplayer4 ;
       else if(num==2)
      return this.$refs.videoplayer3 ;
       else if(num==3)
      return this.$refs.videoplayer2 ;
       else if(num==4)
      return this.$refs.videoplayer1 ;
    },
    setSlide(num){
      this.VideoSwiper.slideTo(num-1);
    },
    downloadPdf() {
      var key = "m" + this.mode + "g" + this.grade;
      window.open("/pdf/" + this.pdfName[key], "_blank").focus();
    },
    setGrade(g) {
      this.grade = g;
    },
    setMode(m) {
      this.mode = m;
    },

    scrollToCourse() {
      const chatLogDiv = document.getElementById("MyCourseDiv");
      var top = chatLogDiv.offsetTop;

      window.scrollTo(0, top);
    },
  }
};
</script>

 <style>

.skill_a{
    position: absolute;
    height: 120px;
    cursor: pointer;
}
.skill_a.active{
    cursor: context-menu;
} 
.skill_a.upper:nth-of-type(1)
{
   left: -5px;
   top: 79px;
}
.skill_a.upper:nth-of-type(2)
{
   left: 40px;
   top: 150px;
}

.skill_a.upper:nth-of-type(3)
{
   left: 143px;
   top: 180px;
}

 .skill_a.upper:nth-of-type(4)
{
   left: 240px;
   top: 150px;
}

.skill_a.upper:nth-of-type(5)
{
    left: 285px;
   top: 75px;
}
.skill_a.left:nth-of-type(1)
{
   top: -5px;
   left: 79px;
}
.skill_a.left:nth-of-type(2)
{
   top: 40px;
   left: 150px;
}

.skill_a.left:nth-of-type(3)
{
    top: 143px;
   left: 180px;
}

 .skill_a.left:nth-of-type(4)
{
    top: 240px;
   left: 150px;
}

.skill_a.left:nth-of-type(5)
{
    top: 285px;
   left: 75px;
}


.skill_a img{
    height: 100%;
}
.skill_a img:last-child {
  display: none;  
}
.skill_a:hover img:last-child {
  display: block;  
}
.skill_a.active img:last-child {
  display: block;  
}
.skill_a.active img:first-child {
  display: none;  
}
.skill_a:hover img:first-child {
  display: none;  
}
</style>
   
